@import '@hh.ru/magritte-ui/breakpoints';
@import '/src/styles/landing';

.section {
    padding: @landing-section-gap-mobile 0;

    .screen-gt-l({
        padding: @landing-section-gap-desktop 0;
    });
}
